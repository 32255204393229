import React, { Component, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Api, { IExamFlow, useApi } from '../api/Api';
import ExamFlowWidget from '../components/ExamFlowWidget';
import UserWidget from '../components/UserWidget';
import WorkspaceSelector from '../components/WorkspaceSelector';

interface IHomePageProps {
}

function HomePage() {
    const [api, setApi] = useState<Api>();
    const [examFlow, setExamFlow] = useState<IExamFlow>();

    var navigate = useNavigate();

    useApi(async (theApi) => {
        if(api == null)
            setApi(theApi);
    });

    const getCourseTitle = () => {
        if (examFlow != null) {
            return (
                <div>
                    <div><b>Course</b></div>
                    {examFlow.course.name}
                </div>
            )
        } else
            return (<></>)
    }

    return (
        <div>
            <div className="row my-3">
                <div className="col-md-4">
                    <div><b>Operator</b></div>
                    <WorkspaceSelector></WorkspaceSelector>
                </div>
                <div className="col-md-4">
                    {getCourseTitle()}
                </div>
                <div className="col-md-4 text-end">
                    <UserWidget></UserWidget>
                </div>
            </div>

            <div className="row">
                <div className="col-md-12">
                    <ExamFlowWidget></ExamFlowWidget>
                </div>
            </div>
        </div>
    );
}

export default HomePage;