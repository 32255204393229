import { useState } from "react";
import { ILearningOutcome } from "../api/Api";
import VcrButtons from "./VcrButtons";

const EfLearningOutcomes: React.FC<{
    los: ILearningOutcome[],
    finish: () => void;
}> = ({
    los,
    finish  
}) => {
    const [index, setIndex] = useState(0);

    const getLo = () => {
        return los[index];
    }

    var lo = getLo();

    const getBullets = () => {
        if (lo.bullets.length > 0) {
            return (
                <div>
                    <ul className="eflearningoutcomes-notes">
                        {lo.bullets.map((note, index) => { 
                            return (
                                <li key={index}>{note}</li>
                            )
                        })}
                    </ul>
                </div>
            )
        } else {
            return (<></>)
        }
    }

    const getInstructorNotes = () => {
        if (lo.instructorNotes.length > 0) {
            return (
                <div>
                    <h5>Instructor Notes</h5>
                    <hr />
                    <ul className="eflearningoutcomes-notes">
                        {lo.instructorNotes.map((note, index) => {
                            return (
                                <li key={index}>{note}</li>
                            )
                        })}
                    </ul>
                </div>
            )
        } else {
            return (<></>)
        }
    }

    if (lo != null) {
        return (
            <div>
                <div className="row">
                    <div className="col-md-6 pe-5">
                        <h5>Learning Outcome {index + 1} of {los.length}</h5>
                        <hr />
                        <div>
                            {lo.name}
                        </div>
                        <div>
                            {getBullets()}
                        </div>
                    </div>
                    <div className="col-md-6 ps-5">
                        {getInstructorNotes()}
                    </div>
                </div>
                <VcrButtons count={los.length} title="Learning Outcomes" onIndexChanged={(index) => setIndex(index)} canFinish={() => Promise.resolve(true)}
                    finish={() => finish()}></VcrButtons>
            </div>
        )

    } else
        return (<></>);
}

export default EfLearningOutcomes;