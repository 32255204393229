import { useState } from "react";
import { Link } from "react-router-dom";
import { IWebSession, useSession, WebUserType } from "../api/Api";

const UserWidget = () => {

    const [session, setSession] = useState<IWebSession>();

    useSession((session) => {
        setSession(session);
    });

    const getUserTypeDisplayName = (type: WebUserType) => {
        if (type === WebUserType.User)
            return "Instructor";
        else if (type === WebUserType.Respondent)
            return "Operator";
        else
            throw new Error("Cannot handle " + type);
    }

    if (session != null) {
        if (session.isAuthenticated) {
            return (
                <div>
                    <div><b>{getUserTypeDisplayName(session.userType)}</b></div>
                    <div>{session.user.fullName}</div>
                    <div><Link to="/logout">Logout</Link></div>
                </div>
            )
        } else {
            return (
                <Link to="/login">Login</Link>
            )
        }
    } else
        return (<></>);
}

export default UserWidget;