import { resolveNaptr } from 'dns/promises';
import React, { useEffect, useState } from 'react';
import { FaHackerNews } from 'react-icons/fa';
import Api, { IAnswerSet, IEnumValue, IShortQuestionWithNotes, useApi, WebUserType } from '../api/Api';
import QuestionContext from '../api/QuestionContext';
import AnswerSetWidget from './AnswerSetWidget';

const EfPracticalTest: React.FC<{
    answerSet: IAnswerSet,
    finish: () => void,
    context: QuestionContext
}> = ({
    answerSet,
    finish,
    context
}) => {
    const [api, setApi] = useState<Api>();
    const [notes, setNotes] = useState<IShortQuestionWithNotes[]>();
    const [majorFailureReasons, setMajorFailureReasons] = useState<IEnumValue[]>();
    const [isEditingMajorReasons, setShowMajorReasons] = useState<boolean>(false);
    const [selectedMajorReasons, setSelectedMajorReasons] = useState<string[]>([]);

    useApi((api) => {
        setApi(api);
    });

    useEffect(() => {
        if (answerSet != null && api != null) {
            var load = async () => {
                if (majorFailureReasons == null) {
                    setMajorFailureReasons(await api!.getMajorFailureReasonsAsync());
                    setSelectedMajorReasons(await api!.getMajorFailureReasonsForWorkspaceAsync());
                }

                if(notes == null)
                    setNotes(await api!.getQuestionNotesForAnswerSetAsync(answerSet!));
            }
            load();
        }
    });

    const toggleIsEditingMajorReasons = () => {
        setShowMajorReasons(!(isEditingMajorReasons));
    }

    const isMajorReasonChecked = (reason: IEnumValue): boolean => {
        return selectedMajorReasons.indexOf(reason.value) !== -1;
    }

    const toggleIsMajorReasonChecked = async (reason: IEnumValue) => {
        const index = selectedMajorReasons.indexOf(reason.value);

        let newReasons = [
            ...selectedMajorReasons,
        ];

        if (index === -1)
            newReasons.push(reason.value);
        else
            newReasons.splice(index, 1);

        setSelectedMajorReasons(newReasons);

        await api!.setMajorFailureReasonsForWorkspaceAsync(newReasons);
    }

    const renderMajorReasonCheck = (reason: IEnumValue) => {
        if (isMajorReasonChecked(reason)) 
            return (<i className="fa-regular fa-square-check"></i>)
        else
            return (<i className="fa-regular fa-square"></i>)    
    }

    const getSelectedMajorReasons = (): IEnumValue[] => {
        const results = new Array<IEnumValue>();
        for (const reason of majorFailureReasons!) {
            if (isMajorReasonChecked(reason))
                results.push(reason);
        }
        return results;
    }

    const getMajorReasonsButtonText = (): string => {
        if (isEditingMajorReasons)
            return "Finish Editing Major Faults"
        else if(selectedMajorReasons.length === 0)
            return "Add Major Fault(s)"
        else
            return "Add/Edit Major Faults"
    }

    const getMajorReasonsButtonClasses = (): string => {
        let css = "btn btn-sm ";
        if (isEditingMajorReasons)
            css += "btn-success";
        else
            css += "btn-warning";
        return css;
    }

    const renderMajorReasons = () => {
        if (majorFailureReasons != null) {
            if (context.userType === WebUserType.Respondent) {
                if (selectedMajorReasons.length > 0) {
                    return (
                        <div className="card bg-light">
                            <div className="card-body">
                                <h3>Major Faults</h3>
                                <ol className="taglist mt-2">
                                    {getSelectedMajorReasons().map((reason) => {
                                        return (
                                            <li key={reason.value}>
                                                {reason.name}
                                            </li>
                                        )
                                    })}
                                </ol>
                            </div>
                        </div>
                    )
                } else
                    return (<></>)
            } else if (context.userType === WebUserType.User) {
                return (
                    <div>
                        <button className={getMajorReasonsButtonClasses()} onClick={() => toggleIsEditingMajorReasons()}>{getMajorReasonsButtonText()}</button>
                        <div className="mt-2">
                            {isEditingMajorReasons && (
                                <ol className="practicalfaults">
                                    {majorFailureReasons.map((reason) => {
                                        return (
                                            <li key={reason.value} className="clickable" onClick={() => toggleIsMajorReasonChecked(reason)}>
                                                {renderMajorReasonCheck(reason)} {reason.name}
                                            </li>
                                        )
                                    })}
                                </ol>
                            )}
                            {!(isEditingMajorReasons) && selectedMajorReasons.length > 0 && (
                                <div>
                                    <div>
                                        <i className="fa-solid fa-lock"></i> Major faults are locked. Click the button to edit.
                                    </div>
                                    <ol className="practicalfaults mt-2">
                                        {getSelectedMajorReasons().map((reason) => {
                                            return (
                                                <li key={reason.value}>
                                                    {renderMajorReasonCheck(reason)} {reason.name}
                                                </li>
                                            )
                                        })}
                                    </ol>
                                </div>
                            )}
                        </div>
                    </div>
                )
            } else
                throw new Error(`Cannot handle '${context.userType}'.`);

        } else
            return (<></>)
    }

    if (answerSet != null && notes != null) {
        return (
            <div>
                <div className="row mt-3 mb-3">
                    <div className="col-md-2">
                    </div>
                    <div className="col-md-8">
                        {renderMajorReasons()}
                    </div>
                    <div className="col-md-2">
                    </div>
                </div>

                <AnswerSetWidget key="PracticalTestAnswerSet" answerSet={answerSet} notes={notes} finish={() => finish()} context={context}></AnswerSetWidget>
            </div>
        );
    } else {
        return (<></>)
    }
}

export default EfPracticalTest;