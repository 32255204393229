import { useState } from "react"
import Api, { IQuestion, QuestionTypeNames, QuestionAnswerMode, QuestionMark, useApi, IAnswerSet, WebUserType, IAnswerAcknowledgement } from "../api/Api"
import QuestionContext from "../api/QuestionContext";
import SignoffLabel from "./1-molecules/SignoffLabel";
import MarkQuestion from "./MarkQuestion";
import MultipleChoiceWidget, { IMultipleChoiceOption } from "./MultipleChoiceWidget";
import PracticalFaultWidget from "./PracticalFaultWidget";

//enum TrainingCategoryAcknowledgementType {
//    Operator = 0,
//    Instructor = 1
//}

class CachedInspectionStates {
    private static states: IMultipleChoiceOption[] | null = null;

    static async getInspectionStatesAsync(api: Api) {
        if (this.states == null)
            this.states = await api.getInspectionStatesAsync();
        return this.states;
    }
}

const QuestionWidget: React.FC<{
    answerSet: IAnswerSet,
    question: IQuestion,
    answer: string,
    answerKey: string | null,
    notes: string[] | null,
    acknowledgement: IAnswerAcknowledgement,
    hasAnswer: boolean,
    answerChanged: (value: string, commit: boolean) => void,
    mode: QuestionAnswerMode,
    mark: QuestionMark,
    answerMarked: (mark: QuestionMark) => void,
    context: QuestionContext,
    hideNotesButton?: boolean
}> = ({
    answerSet,
    question,
    answer,
    answerKey,
    notes,
    acknowledgement,
    hasAnswer,
    answerChanged, 
    mode,
    mark,
    answerMarked,
    context,
    hideNotesButton
}) => {
    const [inspectionOptions, setInspectionOptions] = useState<IMultipleChoiceOption[]>();
    const [_acknowledgement, _setAcknowledgement] = useState<IAnswerAcknowledgement>(acknowledgement);
    const [api, setApi] = useState<Api>();

    useApi(async (theApi) => {
        const values = await CachedInspectionStates.getInspectionStatesAsync(theApi);
        setInspectionOptions(values);

        setApi(theApi);
    });

    const getValue = () => {
        return answer;
    }

    const setValue = (value: any, commit: boolean) => {
        answer = value;
        answerChanged(value, commit);
    }

    const handleBlur = () => {
    }

    const render = () => {
        if (mode == QuestionAnswerMode.ReadWrite) {
            if (question.type == QuestionTypeNames.Text)
                return renderTextEditor();
            else if (question.type == QuestionTypeNames.MultipleChoice)
                return renderMultipleChoiceEditor();
            else if (question.type == QuestionTypeNames.InspectionState)
                return renderInspectionStateEditor();
            else if (question.type == QuestionTypeNames.PracticalFault)
                return renderPracticalFaultEditor();
            else if (question.type == QuestionTypeNames.TrainingCategory) {
                return renderTrainingCategoryEditor();
            } else
                throw new Error("Cannot handle question type for read/write: " + question.type);

        } else if (mode == QuestionAnswerMode.Mark) {
            return renderMarkQuestion();

        } else if (mode == QuestionAnswerMode.ReadOnly) {
            return renderReadOnly();

        } else
            throw new Error("Cannot handle mode: " + mode);
    }

    const getNotesBullets = () => {
        if (notes != null && notes.length > 0) {
            return (
                <ul>
                    {notes.map((note) => {
                        return (
                            <li>{note}</li>
                        )
                    })}
                </ul>
            )
        } else {
            return (<></>)
        }
    }

    const anknowledgeAnswer = async () => {
        const ack = await api!.acknowledgeAnswerAsync(answerSet, question);
        _setAcknowledgement(ack);
    }

    const setAnswerAsNotApplicable = async () => {
        const ack = await api!.setAnswerAsNotApplicableAsync(answerSet, question);
        _setAcknowledgement(ack);
    }

    const setAnswerAsApplicable = async () => {
        const ack = await api!.setAnswerAsApplicableAsync(answerSet, question);
        _setAcknowledgement(ack);
    }

    const renderAcknowledgementButton = (name: string, asButton: boolean, value: boolean, isNotApplicable: boolean) => {
        if (asButton) {
            if (!(value)) {

                let css = "btn btn-sm ";
                if (isNotApplicable)
                    css += "btn-secondary";
                else
                    css += "btn-success"

                return (
                    <button className={css} onClick={() => anknowledgeAnswer()}>{name} Acknowledges</button>
                )
            }
        } 

        return (
            <SignoffLabel isSignedOff={value} signedOffText={name + " acknowledged"} notSignedOffText={name + " not acknowledged"}></SignoffLabel>
        )
    }

    const renderTrainingCategoryEditor = () => {
        return renderAnswerAcknowledgementEditor();
    }

    const renderInspectionStateEditor = () => {
        return renderAnswerAcknowledgementEditor(true);
    }

    const renderAnswerAcknowledgementEditor = (showNotApplicable?: boolean) => { 
        showNotApplicable = showNotApplicable ?? false;

        let ack = _acknowledgement;
        if (ack == null) {
            ack = {
                hasRespondentAcknowledgement: false,
                hasInstructorAcknowledgement: false,
                isNotApplicable: false
            }
        }

        let className = "col-md-6";
        if (showNotApplicable)
            className = "col-md-4";

        const renderNotApplicable = () => {
            if (showNotApplicable) {
                if (context.userType === WebUserType.User) {
                    if (!(ack.isNotApplicable)) {
                        return (
                            <div className={className}>
                                <button className="btn btn-sm btn-warning" onClick={() => setAnswerAsNotApplicable()}>Not Applicable</button>
                            </div>
                        )
                    } else {
                        return (
                            <div className={className}>
                                <SignoffLabel isSignedOff={true} signedOffText={"Not applicable"}></SignoffLabel>
                                {context.userType === WebUserType.User &&
                                    <a className="clickable" onClick={() => setAnswerAsApplicable()}><i className="fa fa-undo"></i> Undo</a>
                                }
                            </div>
                        )
                    }
                } else {
                    if (ack.isNotApplicable) {
                        return (
                            <div className={className}>
                                <SignoffLabel isSignedOff={true} signedOffText={"Not applicable"}></SignoffLabel>
                            </div>
                        )
                    }
                }
            } 

            return (<></>)
        }

        const renderRespondentOptions = () => {
            if (!(ack.isNotApplicable)) {
                if (context.userType === WebUserType.Respondent)
                    return renderAcknowledgementButton("Respondent", true, ack.hasRespondentAcknowledgement, ack.isNotApplicable)
                if (context.userType === WebUserType.User)
                    return renderAcknowledgementButton("Respondent", false, ack.hasRespondentAcknowledgement, ack.isNotApplicable)
            }

            return (<></>);
        }

        const renderInstructorOptions = () => {
            if (!(ack.isNotApplicable)) {
                if (context.userType === WebUserType.Respondent)
                    return renderAcknowledgementButton("Instructor", false, ack.hasInstructorAcknowledgement, ack.isNotApplicable)
                if (context.userType === WebUserType.User)
                    return renderAcknowledgementButton("Instructor", true, ack.hasInstructorAcknowledgement, ack.isNotApplicable)
            }

            return (<></>);
        }

        return (
            <div>
                <div>{getNotesBullets()}</div>
                <div className="row">
                    <div className={className}>
                        {renderRespondentOptions()}
                    </div>
                    <div className={className}>
                        {renderInstructorOptions()}
                    </div>
                    {renderNotApplicable()}
                </div>
            </div>
        )
    }

    const renderMarkQuestion = () => {
        if (hasAnswer) {
            return (
                <MarkQuestion question={question} answer={getValue()} answerKey={answerKey} answerMarked={answerMarked} mark={mark}></MarkQuestion>
            )
        } else {
            return (
                <div className="alert alert-warning">Not answered</div>
            )
        }
    }

    const renderReadOnly = () => {
        const getClassNames = () => {
            if (question.type == QuestionTypeNames.InspectionState) {
                if (answer == null || answer.length == 0)
                    return "alert alert-default";
                else if (answer == "Pass")
                    return "alert alert-success";
                else if (answer == "Fail")
                    return "alert alert-danger";
                else if (answer == "NA")
                    return "alert alert-info";
                else
                    throw new Error("Cannot handle " + answer + " (1).");

            } else {
                if (mark == QuestionMark.NotMarked || mark == undefined)
                    return "alert alert-warning";
                else if (mark == QuestionMark.Pass)
                    return "alert alert-success";
                else if (mark == QuestionMark.Fail)
                    return "alert alert-danger";
                else
                    throw new Error("Cannot handle " + mark + " (2).");
            }
        }

        return (
            <div className={getClassNames()}>{getValue()}</div>
            )
    }

    const renderTextEditor = () => {
        return (<textarea key={question.token} className="form-control" value={getValue()} onChange={(e) => setValue(e.target.value, false)}
            onBlur={(e) => setValue(e.target.value, true)}></textarea>);
    }

    const renderMultipleChoiceEditor = () => {
        return (
            <ol key={question.token} className="question-multiplechoice">
                {question.options.map((option) => {
                    return (
                        <li key={option.token} onClick={(e) => setValue(option.token, true)}>
                            <span className="question-multiplechoice-number">{option.number})</span>
                            <input type="checkbox" checked={option.token == getValue()} onBlur={() => handleBlur()} /> {option.name}
                        </li>
                    )
                })}
            </ol>
        )
    }

    const renderPracticalFaultEditor = () => {
        return (
            <PracticalFaultWidget key={question.token} answerSet={answerSet} question={question} answer={getValue()} onChange={(value) => setValue(value, true)}></PracticalFaultWidget>
        )
    }

    const showNotes = () => {
        alert(notes);
    }

    if (question != null) {
        return (
            <div>
                <h5><span className="question-number">{question.number}.</span>{question.name}
                    {notes != null && notes.length > 0 && !(hideNotesButton) && <span className="clickable" onClick={() => showNotes()}>Show Notes</span>}
                </h5>
                <div className="question-question">
                    {render()}
                </div>
                <hr />
            </div>
        )
    } else
        return (<></>)
    }

export default QuestionWidget;