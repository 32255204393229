/* eslint-disable no-restricted-globals */
export class UiHelper {
    static confirm(args: string | (() => void), callback?: () => void) {
        let message = "";
        if (typeof args == "string")
            message = args as string;
        else {
            callback = args as () => void;
            message = "Are you sure?";
        }

        if (confirm(message))
            callback!();
    }
}