import { useNavigate } from "react-router-dom";
import { useApi } from "../api/Api";
import NavigateHelper from "../api/NavigateHelper";

const LogoutPage = () => {

    var navigate = useNavigate();

    useApi(async (api) => {
        await api.logoutAsync();
        new NavigateHelper(navigate).goLogin(); 
    });

    return (<></>)
}

export default LogoutPage;
