import { ICourseObjectives } from "../api/Api";
import EfCaption from "./EfCaption";

const EfCourseObjectives: React.FC<{ courseObjectives: ICourseObjectives }> = ({ courseObjectives }) => {

    if (courseObjectives != null) {
        return (
            <div>
                <EfCaption text={"It is envisaged that by the end of this course of training the trainee operator will be able to answer quetsions on and perform the following:"}></EfCaption>

                <div className="row mt-3">
                    <div className="col-md-2">
                    </div>
                    <div className="col-md-8">
                        <ul>
                            {courseObjectives.details.map((item, index) => {
                                return (
                                    <li key={index}>{item}</li>
                                )
                            })}
                        </ul>
                    </div>
                    <div className="col-md-2">
                    </div>
                </div>

            </div>
        )
    } else
        return (<></>);
}

export default EfCourseObjectives;