const YesNoLabel: React.FC<{
	value: boolean,
}> = ({
	value,
}) => {
		return (
			<div className="my-2">
				{value &&
					<div>&quot;Yes&quot;</div>}
				{!(value) &&
					<div>&quot;No&quot;</div>}
			</div>
		);
	}

export default YesNoLabel;