import { IAnswerSet, IWebSession, WebUserType } from "./Api";

class QuestionContext {
    private session: IWebSession;

    constructor(session: IWebSession) {
        this.session = session;
    }

    public get userType(): WebUserType {
        return this.session.userType
    }

//    getReadWriteMode(answerSet: IAnswerSet): QuestionAnswerMode {

//        if (answerSet.type == QuestionSetTypeNames.OperatorProfile) {
//            // either can do it...
//            return QuestionAnswerMode.ReadWrite;

//        } else if (answerSet.type == QuestionSetTypeNames.TheoryExam) {
//            // users can mark, respondents/operators can change...
//            if (this.session.userType == UserTypeNames.User)
//                return QuestionAnswerMode.Mark;  
//            else if (this.session.userType == UserTypeNames.Respondent)
//                return QuestionAnswerMode.ReadWrite;
//            else
//                throw new Error("Cannot handle user type for theory exam: " + this.session.userType);

//        } else if (answerSet.type == QuestionSetTypeNames.PracticalExam) {
//            // respondents/operators can review, users can change...
//            if (this.session.userType == UserTypeNames.User)
//                return QuestionAnswerMode.ReadWrite;
//            else if (this.session.userType == UserTypeNames.Respondent)
//                return QuestionAnswerMode.ReadOnly;
//            else
//                throw new Error("Cannot handle user type for practical exam: " + this.session.userType);

//        } else if (answerSet.type == QuestionSetTypeNames.Inspection) {
//            // respondents/operators can review, users can change...
//            if (this.session.userType == UserTypeNames.User)
//                return QuestionAnswerMode.ReadWrite;
//            else if (this.session.userType == UserTypeNames.Respondent)
//                return QuestionAnswerMode.ReadOnly;
//            else
//                throw new Error("Cannot handle user type for inspection: " + this.session.userType);
//        } else
//            throw new Error("Cannot handle question set type: " + answerSet.type);
//    }
}

export default QuestionContext;