const FormGroup: React.FC<{
        children: React.ReactNode
    }> = ({
        children
    }) => {
    return (
        <div className="form-group mb-2">
            {children}
        </div>
    );
}

export default FormGroup;