import { useEffect, useState } from "react";
import Api, { IQuestion, QuestionMark, useApi } from "../api/Api";

const MarkQuestion: React.FC<{
    question: IQuestion,
    answer: string,
    answerKey?: string | null,
    answerMarked: (mark: QuestionMark) => void,
    mark: QuestionMark
}> = ({
    question,
    answer,
    answerKey,
    answerMarked,
    mark
}) => {
    const [api, setApi] = useState<Api>();
    const [theMark, _setTheMark] = useState<QuestionMark>();

    useApi((api) => {
        setApi(api);
    });

    useEffect(() => {
        _setTheMark(mark)
    });

    const setMark = async (mark: QuestionMark) => {
        _setTheMark(mark);
        answerMarked(mark);
    }

    const getClasses = () => {
        if (mark == QuestionMark.NotMarked)
            return "alert alert-info";
        else if (mark == QuestionMark.Pass)
            return "alert alert-success";
        else if (mark == QuestionMark.Fail)
            return "alert alert-danger";
        else
            throw new Error("Cannot handle: " + mark);
    }

    const getCheckbox = (text: string, markType: QuestionMark) => {
        return (
            <div onClick={() => setMark(markType)}>
                <input type="checkbox" checked={theMark == markType} /> {text}
            </div>
            )
    }

    const getAnswerKey = () => {
        if (answerKey != null && answerKey.length > 0) {
            return (
                <div className="markquestion-answerkey">
                    <span>Answer key: {answerKey}</span>
                </div>
                )

        } else {
            return (<></>)
        }
    }

    if (api != null) {
        return (
            <div className={getClasses()}>
                <div>{answer}</div>
                {getAnswerKey()}
                <ol className="markquestion-options">
                    <li>{getCheckbox("Not marked", QuestionMark.NotMarked)}</li>
                    <li>{getCheckbox("Pass", QuestionMark.Pass)}</li>
                    <li>{getCheckbox("Fail", QuestionMark.Fail)}</li>
                </ol>
            </div>
        )

    } else {
        return (<></>)
    }
}

export default MarkQuestion;