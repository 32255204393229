import React, { useState } from 'react';
import Api, { IAnswerSet, IQuestion, useApi } from '../api/Api';
import QuestionContext from '../api/QuestionContext';

const PracticalFaultWidget: React.FC<{
    answerSet: IAnswerSet,
    question: IQuestion,
    answer: string,
    onChange: (value: number) => void,
}> = ({
    answerSet,
    question,
    answer,
    onChange,
}) => {
    const [api, setApi] = useState<Api>();
    const [value, _setValue] = useState<number>();

    useApi(async (api) => {
        setApi(api);
    });

    if (value == null) {
        if (answer != null && answer.length > 0)
            _setValue(Number(answer));
        else
            _setValue(0);
    }

    const addFault = async () => {
        var newScore = (await api!.addPracticalExamFaultForCurrentWorkspaceAsync(answerSet, question)).value;
        setValue(newScore);
    }

    const removeFault = async () => {
        var newScore = (await api!.removePracticalExamFaultForCurrentWorkspaceAsync(answerSet, question)).value;
        setValue(newScore);
    }

    const setValue = (value: number) => {
        _setValue(value);
        onChange(value);
    }

    const getClasses = () => {
        var cs = "question-practicalfault alert ";
        if (value! == 0)
            cs += " alert-success"
        else
            cs += " alert-danger"
        return cs;
    }

    const getValue = () => {
        if (value == 1)
            return "1 penalty point";
        else
            return value + " penalty points";
    }

    return (
        <div className={getClasses()}>
            <div className="question-practicalfault-value">{getValue()}</div>
            <div className="question-practicalfault-buttons mt-2">
                <button className="btn btn-sm btn-danger" onClick={() => addFault()}>Add Fault</button>
                {Number(value) > 0 &&
                    <button className="btn btn-sm btn-warning ms-1" onClick={() => removeFault()}>Remove Fault</button>
                }
            </div>
        </div>
    );
}

export default PracticalFaultWidget;