import React, { ChangeEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Api, { IWebSession, IWorkspace, useApi } from '../api/Api';
import NavigateHelper from '../api/NavigateHelper';

function WorkspaceSelector() {
    const [api, setApi] = useState<Api>();
    const [currentWorkspace, setCurrentWorkspace] = useState<IWorkspace>();
    const [workspaces, setWorkspaces] = useState<IWorkspace[]>();

    useApi(async (api) => {
        setApi(api);

        var workspaces = await api.getWorkspacesAsync();
        setWorkspaces(workspaces.items);
        setCurrentWorkspace(workspaces.currentWorkspace);
    });

    var navigate = useNavigate();

    const handleChange = async (e: ChangeEvent<HTMLSelectElement>) => {
        for (var ws of workspaces!) {
            if (ws.token == e.target.value) {
                await api?.setCurrentWorkspaceAsync(ws);
                new NavigateHelper(navigate).goHome();
                return;
            }
        }

        throw new Error("Failed to find workspace.");
    };

    if (workspaces != null) {
        return (
            <span>
                <select value={currentWorkspace?.token} onChange={handleChange}>
                    {workspaces.map((item) => {
                        return (
                            <option key={item.token} value={item.token}>
                                {item.name}
                            </option>
                        );
                    })}
                </select>
            </span>
        );
    } else {
        return (<></>);
    }
}

export default WorkspaceSelector;