import React, { useEffect, useState } from 'react';
import Api, { IAnswerSet, IShortQuestionWithNotes, useApi } from '../api/Api';
import QuestionContext from '../api/QuestionContext';
import AnswerSetWidget from './AnswerSetWidget';

const EfTrainingRecord: React.FC<{
    answerSet: IAnswerSet,
    finish: () => void,
    context: QuestionContext
}> = ({
    answerSet,
    finish,
    context
}) => {
    const [api, setApi] = useState<Api>();
    const [notes, setNotes] = useState<IShortQuestionWithNotes[]>();

    useApi((theApi) => {
        setApi(theApi);
    });

    useEffect(() => {
        if (answerSet != null && api != null && notes == null) {
            var get = async () => {
                setNotes(await api!.getQuestionNotesForAnswerSetAsync(answerSet!));
            }
            get();
        }
    });

    const handleChange = (el: HTMLInputElement) => {
    }

    const handleBlur = async (el: HTMLInputElement) => {
        await api?.setVehicleDetailsFieldForCurrentWorkspaceAsync(el.name, el.value);
    }

    if (answerSet != null && notes != null) {
        return (
            <div>
                <AnswerSetWidget key="TrainingRecordAnswerSet" answerSet={answerSet} notes={notes} hideNotesButton={true} finish={() => finish()} context={context}></AnswerSetWidget>
            </div>
        );
    } else {
        return (<></>)
    }
}

export default EfTrainingRecord;