const EfResultsPendingScore: React.FC<{
    text: string

}> = ({
    text 

}) => {

    return (
        <div className="results-score results-score-yellow">
            {text}
        </div>
    )
}

export default EfResultsPendingScore;