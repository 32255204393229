import { createContext, useEffect } from "react";
import Cookies from "js-cookie";
import React from "react";
import { showAlert } from "./Helpers";

//export class UserTypeNames {
//    static get User(): string { return "User"; }
//    static get Respondent(): string { return "Respondent"; }
//}

interface IEntity {
    token: string;
}

export interface IResponseBase {
    hasErrors: boolean;
    errors: string[];
}

export interface INullResponse extends IResponseBase {
}

export interface IScalarResponse<T> extends IResponseBase {
    value: T;
}

export interface IEnumValue {
    name: string;
    value: string;
}

export class QuestionTypeNames {
    static get Text() { return "Text" };
    static get MultipleChoice() { return "MultipleChoice" };
    static get InspectionState() { return "InspectionState" };
    static get PracticalFault() { return "PracticalFault" };
    static get TrainingCategory() { return "TrainingCategory" };
}

export interface IQuestion extends IEntity {
    number: string;
    name: string;
    type: string;
    penaltyPoints: number;
    options: IQuestionOption[];
}

export interface IQuestionOption extends IEntity {
    number: string;
    name: string;
}

export interface IQuestionGroup extends IEntity {
    name: string;
    questions: IQuestion[];
}

export enum QuestionMark {
    NotMarked = 0,
    Pass = 1,
    Fail = 2
}

export enum WebUserType {
    Respondent = "Respondent",
    User = "User"
}

export enum TrainingNeeds {
    FullTrainingRequired = "FullTrainingRequired",
    ReducedTrainingSufficient = "ReducedTrainingSufficient",
    NoTrainingRequired = "NoTrainingRequired"
}

export enum QuestionSetType {
    TheoryTest,
    PracticalTest,
    OperatorProfile,
    Inspection,
    TrainingRecord
}

export class QuestionSetTypeNames {
    static get TheoryTest() { return "TheoryTest" };
    static get PracticalTest() { return "PracticalTest" };
    static get OperatorProfile() { return "OperatorProfile" };
    static get Inspection() { return "Inspection" };
    static get TrainingRecord() { return "TrainingRecord" };
}

export enum QuestionAnswerMode {
    ReadOnly = 0,
    ReadWrite = 1,
    Mark = 2
}

export class QuestionAnswerModeNames {
}

export interface IQuestionSetVersion extends IEntity {
    name: string;
    version: number;
    groups: IQuestionGroup[];
}

export interface IAnswerSet extends IEntity {
    version: IQuestionSetVersion;
    answers: IAnswerItem[];
    type: string;
    mode: string;
}

export interface IAnswerItem {
    questionToken: string; 
    answer: string;
    mark: string;
    acknowledgement: IAnswerAcknowledgement;
}

export interface IAnswerAcknowledgement {
    hasRespondentAcknowledgement: boolean;
    hasInstructorAcknowledgement: boolean;
    isNotApplicable: boolean;
}

export interface IAnswerKeyItem {
    token: string;
    answer: string;
}

export interface IExamFlow extends IEntity {
    name: string;
    course: ICourse;
    reservation: IReservation;
    instructor: IShortUser;
    instructorRenderedAssistance: boolean;
}

export interface IReservation extends IEntity {
    course: ICourse;
    dates: IReservationDate[];
}

export interface IReservationDate extends IEntity {
    dateAsString: string;
    startTimeAsString: string;
    endTimeAsString: string;
}

export interface ICourse extends IEntity {
    name: string;
}

export interface IWebSession {
    token: string;
    workspaceToken: string;
    isAuthenticated: any;
    user: IShortUser;
    userType: WebUserType;
}

export interface IShortUser {
    token: string;
    type: string;
    fullName: string;
}

export interface ILoginUserRequest {
    email: string;
    password: string;
    rememberMe: boolean;
}

export interface IWorkspace extends IEntity {
    name: string;
}

export interface IGetWorkspacesResponse {
    examFlowToken(examFlowToken: any): any;
    items: IWorkspace[];
    currentWorkspace: IWorkspace;
}

export interface ILearningOutcome {
    name: string;
    bullets: string[];
    instructorNotes: string[];
}

export interface ICourseObjectives {
    details: string[];
}

export interface IInspectionVehicleDetails {
    registration: string;
    make: string;
    model: string;
    variations: string;
    attachments: string;
    other: string;
}

export interface ICourseResults {
    theoryTestResults: ITheoryTestResults;
    inspectionResults: IInspectionResults;
    practicalTestResults: IPracticalTestResults;
    majorFailureReasons: string[];
}

export interface ICourseResultsItem {
    numQuestions: number;
    numNotAnswered: number;
}

export interface ITheoryTestResults extends ICourseResultsItem {
    numPassed: number;
    numFailed: number;
    hasScore: boolean;
    score: number;
    passFail: string;
}

export interface IInspectionResults extends ICourseResultsItem {
    numNotApplicable: number;
    numPassed: number;
    numFailed: number;
    overallMark: string;
}

export interface IPracticalTestResults extends ICourseResultsItem {
    score: number;
    practicalTestThreshold: number;
    passFail: string;
    majorFailureReasons: IEnumValue[];
}

export interface IShortQuestionWithNotes extends IEntity {
    notes: string[];
}

export interface IRespondent extends IEntity {
    firstName: string;
    lastName: string;
    dateOfBirth: string;
    machineType: string;
    detailsOfPreviousTraining: string;
    healthAndSafetyPassed: boolean;
    healthAndSafetyPassedDate: string;
    trainingNeeds: TrainingNeeds;
    fullDurationBasicTrainingReasons: string;
    courseDurationReducedReasons: string;
    trainingRequirementDays: number;
    hasRespondentSignedOff: boolean;
    hasInstructorSignedOff: boolean;
}

export interface IEmploymentDetail extends IEntity {
    name: string;
    dates: string;
    details: string;
}

export interface ITrainingCategoryAcknowledgement {
    operator: boolean,
    instructor: boolean
}

export interface IAddEmploymentDetailsRequest {
    name: string;
    dates: string;
    details: string;
}

class Busy {
    private static _instance: Busy = new Busy();

    static get instance(): Busy {
        return Busy._instance;
    }

    start(): Busy {
        return Busy._instance;
    }

    complete(busy: Busy) {
    }
}

const SessionKey = "__nporsSession";

export function useSession(callback: (session: IWebSession) => void): void {
    var session = React.useContext(SessionContext);

    useApi(async (api) => {
        if (session == null) {
            session = await api.getSession();
        }
        callback(session);
    });
}

export function useApi(callback: (api: Api) => void): void {
    //const [session, setSession] = useState<IWebSession>();

    const session = React.useContext(SessionContext);
    useEffect(() => {

        var run = async () => {
            var api = new Api();

            if (session == null) {
                var theSession = await api.getSession();
                console.log("session --> ", theSession.token);
                Cookies.set(SessionKey, theSession.token, { expires: 7 });
                //setSession(theSession);
            }

            callback(api);
        };

        run();

    }, []);
}

export const SessionContext = createContext<IWebSession | null>(null);

class Api {
    static getServiceUrl(uri: string): string {
        if (!((uri as any).startsWith("/")))
            uri = "/" + uri;
        return "https://npors-examplatform-api.oncodebeam.co.uk" + uri;
//        return "https://localhost:7271" + uri;
    }

    private async callApiAsync(uri: string, method: string, data: any): Promise<any> {
        var busy = Busy.instance.start();
        var url = Api.getServiceUrl(uri);
        var result = await ($ as any).ajax({
            url: url,
            method: method,
            data: JSON.stringify(data),
            dataType: "json",
            contentType: "application/json",
            headers: {
                "x-npors-session": Cookies.get(SessionKey)
            },
            error: (jqXhr: any, status: any, error: string) => {
                showAlert(error);
                return;
            },
            complete: () => {
                Busy.instance.complete(busy);
            }
        });

        if (result == null || !(result.hasErrors)) {
            return result;
        } else {
            showAlert(result.errors);
            return result;
        }
    }

    private getAsync(uri: string): Promise<any> {
        return this.callApiAsync(uri, "GET", null);
    }

    private postAsync(uri: string, args: any): Promise<any> {
        return this.callApiAsync(uri, "POST", args);
    }

    private putAsync(uri: string, args: any): Promise<any> {
        return this.callApiAsync(uri, "PUT", args);
    }

    private deleteAsync(uri: string): Promise<void> {
        return this.callApiAsync(uri, "DELETE", null);
    }

    getQuestionGroupAsync(token: string): Promise<IQuestionGroup> {
        return this.getAsync("/questiongroups/" + encodeURIComponent(token));
    }

    getAnswerSetAsync(token: string): Promise<IAnswerSet> {
        return this.getAsync("/examflows/answersets/" + encodeURIComponent(token));
    }

    setAnswersAsync(answerSet: IAnswerSet, questionGroup: IQuestionGroup, answers: { [key: string]: string }) {
        return this.postAsync("/examflows/answersets/" + encodeURIComponent(answerSet.token) + "/questiongroups/" + encodeURIComponent(questionGroup.token), {
            answers: answers
        });
    }

    getSession(): Promise<IWebSession> {
        return this.getAsync("/application/websession");
    }

    logoutAsync(): Promise<INullResponse> {
        return this.getAsync("/application/logout");
    }

    loginUserAsync(args: ILoginUserRequest): Promise<INullResponse> {
        return this.postAsync("/application/loginuser", args);
    }

    loginRespondent(args: ILoginUserRequest): Promise<INullResponse> {
        return this.postAsync("/application/loginrespondent", args);
    }

    getWorkspacesAsync(): Promise<IGetWorkspacesResponse> {
        return this.getAsync("/workspaces");
    }

    setCurrentWorkspaceAsync(item: IWorkspace): Promise<INullResponse> {
        return this.putAsync("/workspaces/current", {
            value: item.token
        });
    }

    getCurrentWorkspaceAsync(): Promise<IWorkspace> {
        return this.getAsync("/workspaces/current");
    }

    getLearningOutcomesForCurrentWorkspaceAsync(): Promise<ILearningOutcome[]> {
        return this.getAsync("/workspaces/current/learningoutcomes");
    }

    getCourseObjectivesForCurrentWorkspaceAsync(): Promise<ICourseObjectives> {
        return this.getAsync("/workspaces/current/courseobjectives");
    }

    getVehicleDetailsForCurrentWorkspaceAsync(): Promise<IInspectionVehicleDetails> {
        return this.getAsync("/workspaces/current/inspectionvehicle");
    }

    setVehicleDetailsFieldForCurrentWorkspaceAsync(name: string, value: any): Promise<INullResponse> {
        return this.putAsync("/workspaces/current/inspectionvehicle/" + encodeURIComponent(name), {
            value: value
        });
    }

    getAnswerSetForCurrentWorkspaceAsync(type: QuestionSetType): Promise<IAnswerSet> {
        return this.getAsync("/workspaces/current/answersets/bytype/" + QuestionSetType[type]);
    }

    getAnswerKeyForCurrentWorkspaceAsync(type: QuestionSetType): Promise<IAnswerKeyItem[]> {
        return this.getAsync("/workspaces/current/answersets/bytype/" + QuestionSetType[type] + "/answerkey");
    }

    setAnswerForCurrentWorkspaceAsync(answerSet: IAnswerSet, question: IQuestion, value: any): Promise<INullResponse> {
        return this.putAsync("/workspaces/current/answersets/" + encodeURIComponent(answerSet.token ) + "/questions/" + encodeURIComponent(question.token) + "/answer", {
            value: String(value)
        });
    }

    getInspectionStatesAsync(): Promise<IEnumValue[]> {
        return this.getAsync("/application/inspectionstates");
    }

    getTrainingNeedsAsync(): Promise<IEnumValue[]> {
        return this.getAsync("/application/trainingneeds");
    }

    getMajorFailureReasonsAsync(): Promise<IEnumValue[]> {
        return this.getAsync("/application/majorfailurereasons");
    }

    getExamFlowForCurrentWorkspaceAsync(): Promise<IExamFlow> {
        return this.getAsync("/workspaces/current/examflow");
    }

    markAnswerAsync(answerSet: IAnswerSet, question: IQuestion, mark: QuestionMark): Promise<INullResponse> {
        return this.putAsync("/workspaces/current/answersets/" + encodeURIComponent(answerSet.token) + "/questions/" + encodeURIComponent(question.token) + "/mark", {
            value: QuestionMark[mark]
        });
    }

    getResultsForCurrentWorkspaceAsync(): Promise<ICourseResults> {
        return this.getAsync("/workspaces/current/results");
    }

    canFinishAnswerSetForCurrentWorkspaceAsync(answerSet: IAnswerSet): Promise<IScalarResponse<boolean>> {
        return this.getAsync("/workspaces/current/answersets/" + encodeURIComponent(answerSet.token) + "/canfinish");
    }

    finishAnswerSetForCurrentWorkspaceAsync(answerSet: IAnswerSet): Promise<INullResponse> {
        return this.getAsync("/workspaces/current/answersets/" + encodeURIComponent(answerSet.token) + "/finish");
    }

    getQuestionNotesForAnswerSetAsync(answerSet: IAnswerSet): Promise<IShortQuestionWithNotes[]> {
        return this.getAsync("/workspaces/current/answersets/" + encodeURIComponent(answerSet.token) + "/questionnotes");
    }

    addPracticalExamFaultForCurrentWorkspaceAsync(answerSet: IAnswerSet, question: IQuestion): Promise<IScalarResponse<number>> {
        return this.getAsync("/workspaces/current/answersets/" + encodeURIComponent(answerSet.token) + "/questions/" + encodeURIComponent(question.token) + "/addfault");
    }

    removePracticalExamFaultForCurrentWorkspaceAsync(answerSet: IAnswerSet, question: IQuestion): Promise<IScalarResponse<number>> {
        return this.getAsync("/workspaces/current/answersets/" + encodeURIComponent(answerSet.token) + "/questions/" + encodeURIComponent(question.token) + "/removefault");
    }

    getEmploymentDetailsForCurrentWorkspaceAsync(): Promise<IEmploymentDetail[]> {
        return this.getAsync("/workspaces/current/employmentdetails");
    }

    addEmploymentDetailsToCurrentWorkspaceAsync(request: IAddEmploymentDetailsRequest): Promise<INullResponse> {
        return this.postAsync("/workspaces/current/employmentdetails/add", request);
    }

    deleteEmploymentDetailsToCurrentWorkspaceAsync(item: IEmploymentDetail): Promise<void> {
        return this.deleteAsync("/workspaces/current/employmentdetails/" + encodeURIComponent(item.token));
    }

    getRepondentForCurrentWorkspaceAsync(): Promise<IRespondent> {
        return this.getAsync("/workspaces/current/respondent");
    }

    updateRespondentForCurrentWorkspaceAsync(respondent: IRespondent): Promise<INullResponse> {
        return this.putAsync("/workspaces/current/respondent", respondent);
    }

    signOffRespondentAsync(): Promise<INullResponse> {
        return this.getAsync("/workspaces/current/respondent/signoff");
    }

    signOffInstructorAsync(): Promise<INullResponse> {
        return this.getAsync("/workspaces/current/instructor/signoff");
    }

    acknowledgeAnswerAsync(answerSet: IAnswerSet, question: IQuestion): Promise<IAnswerAcknowledgement> {
        return this.getAsync("/workspaces/current/answersets/" + encodeURIComponent(answerSet.token) + "/questions/" + encodeURIComponent(question.token) + "/acknowledge");
    }

    setAnswerAsNotApplicableAsync(answerSet: IAnswerSet, question: IQuestion): Promise<IAnswerAcknowledgement> {
        return this.getAsync("/workspaces/current/answersets/" + encodeURIComponent(answerSet.token) + "/questions/" + encodeURIComponent(question.token) + "/setasnotapplicable");
    }

    setAnswerAsApplicableAsync(answerSet: IAnswerSet, question: IQuestion): Promise<IAnswerAcknowledgement> {
        return this.getAsync("/workspaces/current/answersets/" + encodeURIComponent(answerSet.token) + "/questions/" + encodeURIComponent(question.token) + "/setasapplicable");
    }

    getMajorFailureReasonsForWorkspaceAsync(): Promise<string[]> {
        return this.getAsync("/workspaces/current/majorfailurereasons");
    }

    setMajorFailureReasonsForWorkspaceAsync(reasons: string[]): Promise<INullResponse> {
        return this.putAsync("/workspaces/current/majorfailurereasons", {
            reasons: reasons
        });
    }
}

export default Api;