import { FaInfoCircle } from "react-icons/fa";

const TextAreaLabel: React.FC<{
	value: string,
}> = ({
	value,
}) => {
	if (value != null && value.length > 0) {
		return (
			<div className="my-2">
				&quot;{value}&quot;
			</div>
		);
	} else {
		return (
			<div className="my-2">
				<div className="alert alert-info">
					<FaInfoCircle></FaInfoCircle> Waiting for answer
				</div>
			</div>
		);
	}
}

export default TextAreaLabel;