import { ITheoryTestResults, QuestionMark } from "../api/Api";
import { stringToEnum } from "../api/Helpers";
import EfResultsPendingScore from "./EfResultsPendingScore";

const EfTheoryTestResults: React.FC<{
    results: ITheoryTestResults;
}> = ({
    results
}) => {

    const getScore = () => {
        if (results.hasScore) {
            var asString = results.score + "%";

            var mark = stringToEnum<QuestionMark>(results.passFail, QuestionMark);

            if (mark == QuestionMark.Pass) {
                return (
                    <div className="results-score results-score-green">
                        Pass: {asString}
                    </div>
                )
            } else {
                return (
                    <div className="results-score results-score-red">
                        Fail: {asString}
                    </div>
                )
            }

        } else { 
            var text = "";
            if (results.numNotAnswered > 0)
                text = "Answer all of the theory questions to see your results";
            else
                text = "The instructor will mark your answers";

            return (
                <EfResultsPendingScore text={text}></EfResultsPendingScore>
            )
        }
    }

    const getNotAnswered = () => {
        if (results.numNotAnswered > 0) {
            return (<div className="results-number results-yellow">
                <label>Not Answered</label>
                {results.numNotAnswered}
            </div>)

        } else
            return (<></>);
    }

    return (
        <div className="results-container">
            <div className="results-header">
                <h5>Theory Test</h5>
            </div>
            <div className="results-body">
                <div className="results-numbers">
                    <div className="results-number">
                        <label>Questions</label>
                        {results.numQuestions}
                    </div>
                    <div className="results-number results-green">
                        <label>Passed</label>
                        {results.numPassed}
                    </div>
                    <div className="results-number results-red">
                        <label>Failed</label>
                        {results.numFailed}
                    </div>
                    {getNotAnswered()}
                </div>
                {getScore()}
            </div>
        </div>
        )
}

export default EfTheoryTestResults;