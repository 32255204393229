import React from "react";
import { createContext, ReactNode, useState } from "react";
import { IWebSession, SessionContext, useApi, useSession } from "../api/Api";

// Component that provides the cache and updater function to its children
export const SessionWrapper: React.FC<{
    children: ReactNode
}>
= ({ children }) => { 

    const [session, setSession] = useState<IWebSession>();

    useSession((session) => {
        setSession(session);
    });

    if (session != null) {
        return (
            <SessionContext.Provider value={session!}>
                {children}
            </SessionContext.Provider>
        );
    }
    else
        return (<></>)
};

export default SessionWrapper;
