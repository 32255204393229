import { NavigateFunction } from "react-router-dom";

class NavigateHelper {
    private _navigate: NavigateFunction;

    constructor(navigate: NavigateFunction) {
        this._navigate = navigate;
    }

    private navigate(url: string, refresh?: boolean) {
        this._navigate(url);

        if (refresh)
            this._navigate(0);
    }

    goHome() {
        this.navigate("/", true);
    }

    goLogin() {
        this.navigate("/login");
    }
}

export default NavigateHelper;