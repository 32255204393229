import { useState } from "react";

const YesNoWidget: React.FC<{
	value: boolean,
	setValue: (value: boolean) => void;
}> = ({
	value,
	setValue
}) => {
	var [_value, _setValue] = useState<boolean>(value);

	const doSetValue = (newValue: boolean) => {
		_setValue(newValue);
		setValue(newValue);
	}

	const getClasses = (value: boolean): string => {
		let buf = "btn btn-sm ";
		if (value == _value)
			buf += "btn-primary"
		else
			buf += "bg-light";
		return buf
	}

	return (
		<div className="my-2">
			<div className="btn-group">
				<button className={getClasses(true)} onClick={() => doSetValue(true)}><span className="mx-2">Yes</span></button>
				<button className={getClasses(false)} onClick={() => doSetValue(false)}><span className="mx-2">No</span></button>
			</div>
		</div>
	);
}

export default YesNoWidget;