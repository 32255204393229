import React, { useEffect, useState } from 'react';
import Api, { IAnswerKeyItem, IAnswerSet, ICourseObjectives, ICourseResults, IExamFlow, IInspectionVehicleDetails, ILearningOutcome, IQuestionSetVersion, IRespondent, IShortQuestionWithNotes, IWebSession, QuestionAnswerMode, QuestionSetType, useApi, useSession } from '../api/Api';
import { showAlert, showBubble, stringToEnum } from '../api/Helpers';
import QuestionContext from '../api/QuestionContext';
import AnswerSetWidget from './AnswerSetWidget';
import EfCourseObjectives from './EfCourseObjectives';
import EfInspection from './EfInspection';
import EfLearningOutcomes from './EfLearningOutcomes';
import EfPracticalTest from './EfPracticalTest';
import EfProfile from './EfProfile';
import EfResults from './EfResults';
import EfTrainingRecord from './EfTrainingRecord';

enum TabNames {
    Profile = "Profile",
    CourseObjectives = "Course Objectives",
    LearningOutcomes = "Learning Outcomes",
    TrainingRecord = "Training Record",
    TheoryTest = "Theory Test",
    Inspection = "Inspection",
    PracticalTest = "Practical Test",
    Results = "Results"
}

abstract class EfTab {
    name: string;
    selected: boolean;

    constructor(name: string) {
        this.name = name;
        this.selected = false;
    }

    get classNames(): string {
        var names = "examflow-tab";
        if (this.selected)
            names += " active";
        return names;
    }

    render() {
        return (<h1>{this.name}</h1>);
    }

    refreshViewAsync(api: Api): Promise<any> {
        return Promise.resolve();
    }
}

class CourseObjectivesTab extends EfTab {
    private setCourseObjectives: any;

    constructor(setCourseObjectives: any) {
        super(TabNames.CourseObjectives);
        this.setCourseObjectives = setCourseObjectives;
    }

    async refreshViewAsync(api: Api): Promise<any> {
        this.setCourseObjectives(await api.getCourseObjectivesForCurrentWorkspaceAsync());
    }
}

class LearningOutcomesTab extends EfTab {
    private setLos: any;

    constructor(setLos: any) {
        super(TabNames.LearningOutcomes);
        this.setLos = setLos;
    }

    async refreshViewAsync(api: Api): Promise<any> {
        this.setLos(await api.getLearningOutcomesForCurrentWorkspaceAsync());
    }
}

abstract class QuestionSetEfTab extends EfTab {
    private setAnswerSet: any;
    type: QuestionSetType;

    constructor(name: string, type: QuestionSetType, setQuestionSet: any) {
        super(name);
        this.setAnswerSet = setQuestionSet;
        this.type = type;
    }

    async refreshViewAsync(api: Api): Promise<any> {
        this.setAnswerSet(await api.getAnswerSetForCurrentWorkspaceAsync(this.type));
    }
}

class ProfileTab extends QuestionSetEfTab {
    private setRespondent: any;
    private setExamFlow: any;

    constructor(setQuestionSet: any, setRespondent: any, setExamFlow: any) {
        super(TabNames.Profile, QuestionSetType.OperatorProfile, setQuestionSet);
        this.setRespondent = setRespondent;
        this.setExamFlow = setExamFlow;
    }

    async refreshViewAsync(api: Api): Promise<any> {
        await super.refreshViewAsync(api);
        this.setRespondent(await api.getRepondentForCurrentWorkspaceAsync());
        this.setExamFlow(await api.getExamFlowForCurrentWorkspaceAsync());
    }
}

class TrainingRecordTab extends QuestionSetEfTab {
    constructor(setQuestionSet: any) {
        super(TabNames.TrainingRecord, QuestionSetType.TrainingRecord, setQuestionSet);
    }
}

class TheoryTestTab extends QuestionSetEfTab {
    private setAnswerKey: any;

    constructor(setQuestionSet: any, setAnswerKey: any) {
        super(TabNames.TheoryTest, QuestionSetType.TheoryTest, setQuestionSet);
        this.setAnswerKey = setAnswerKey;
    }

    async refreshViewAsync(api: Api): Promise<any> {
        await super.refreshViewAsync(api);
        this.setAnswerKey(await api.getAnswerKeyForCurrentWorkspaceAsync(this.type));
    }
}

class InspectionTab extends QuestionSetEfTab {
    private setVehicleDetails: any;

    constructor(setQuestionSet: any, setVehicleDetails: any) {
        super(TabNames.Inspection, QuestionSetType.Inspection, setQuestionSet);
        this.setVehicleDetails = setVehicleDetails;
    }

    async refreshViewAsync(api: Api): Promise<any> {
        await super.refreshViewAsync(api);
        this.setVehicleDetails(await api.getVehicleDetailsForCurrentWorkspaceAsync());
    }
}

class PracticalTestTab extends QuestionSetEfTab {
    constructor(setQuestionSet: any) {
        super(TabNames.PracticalTest, QuestionSetType.PracticalTest, setQuestionSet)
    }
}

class ResultsTab extends EfTab {
    private setResults: any;
    private setExamFlow: any;

    constructor(setResults: any, setExamFlow: any) {
        super(TabNames.Results)
        this.setResults = setResults;
        this.setExamFlow = setExamFlow;
    }

    async refreshViewAsync(api: Api): Promise<any> {
        this.setResults(await api.getResultsForCurrentWorkspaceAsync());
        this.setExamFlow(await api.getExamFlowForCurrentWorkspaceAsync());
    }
}

function ExamFlowWidget() {
    const [foo, setFoo] = useState(0);
    const [los, setLos] = useState<ILearningOutcome[]>();
    const [respondent, setRespondent] = useState<IRespondent>();
    const [examFlow, setExamFlow] = useState<IExamFlow>();
    const [courseObjectives, setCourseObjectives] = useState<ICourseObjectives>();
    const [profileAnswerSet, setProfileAnswerSet] = useState<IAnswerSet>();
    const [trainingRecordAnswerSet, setTrainingRecordAnswerSet] = useState<IAnswerSet>();
    const [theoryAnswerSet, setTheoryAnswerSet] = useState<IAnswerSet>();
    const [theoryAnswerKey, setTheoryAnswerKey] = useState<IAnswerKeyItem[]>();
    const [inspectionAnswerSet, setInspectionAnswerSet] = useState<IAnswerSet>();
    const [inspectionVehicleDetails, setInspectionVehicleDetails] = useState<IInspectionVehicleDetails>();
    const [practicalAnswerSet, setPracticalAnswerSet] = useState<IAnswerSet>();
    const [results, setResults] = useState<ICourseResults>();

    const [tabs, setTabs] = useState<EfTab[]>([
        new ProfileTab(setProfileAnswerSet, setRespondent, setExamFlow),
        new CourseObjectivesTab(setCourseObjectives),
        new LearningOutcomesTab(setLos),
        new TrainingRecordTab(setTrainingRecordAnswerSet),
        new TheoryTestTab(setTheoryAnswerSet, setTheoryAnswerKey),
        new InspectionTab(setInspectionAnswerSet, setInspectionVehicleDetails),
        new PracticalTestTab(setPracticalAnswerSet),
        new ResultsTab(setResults, setExamFlow)
    ]);

    const [api, setApi] = useState<Api>();
    const [session, setSession] = useState<IWebSession>();

    useApi((theApi) => {
        setApi(theApi);
    });

    useSession((session) => {
        setSession(session);
    });

    const setCurrentTab = async (tab: EfTab) => {
        for (var walk of tabs!)
            walk.selected = walk.name == tab.name;

        await tab.refreshViewAsync(api!);

        // update...
        setFoo(foo + 1);
    }

    const setNextTab = () => {
        var current = getCurrentTab();
        for (var index in tabs!) {
            var tab = tabs![index];
            if (tab == current) {
                if (Number(index) < tabs!.length - 1)
                    setCurrentTab(tabs![Number(index) + 1]);
                break;
            }
        }
    }

    const getCurrentTab = () => {
        for (var tab of tabs!) {
            if (tab.selected)
                return tab;
        }
        return null;
    }

    const answersFinished = (mode: string) => {
        var theMode = stringToEnum<QuestionAnswerMode>(mode, QuestionAnswerMode);
        if (theMode == QuestionAnswerMode.ReadWrite)
            showAlert("Your answers have been received.");
        setNextTab();
    }

    const getTab = () => {
        var current = getCurrentTab();
        if (current != null) {

            var context = new QuestionContext(session!);

            if (current.name == TabNames.Profile)
                return (<EfProfile key={current.name} answerSet={profileAnswerSet!} respondent={respondent!} examFlow={examFlow!} finish={() => answersFinished(profileAnswerSet!.mode)} context={context}></EfProfile>);
            else if (current.name == TabNames.CourseObjectives)
                return (<EfCourseObjectives courseObjectives={courseObjectives!}></EfCourseObjectives>);
            else if (current.name == TabNames.LearningOutcomes)
                return (<EfLearningOutcomes los={los!} finish={() => setNextTab()}></EfLearningOutcomes>);
            else if (current.name == TabNames.TrainingRecord)
                return (<EfTrainingRecord key={current.name} answerSet={trainingRecordAnswerSet!} finish={() => answersFinished(trainingRecordAnswerSet!.mode)} context={context}></EfTrainingRecord>);
            else if (current.name == TabNames.TheoryTest)
                return (<AnswerSetWidget key={current.name} answerSet={theoryAnswerSet!} answerKey={theoryAnswerKey} finish={() => answersFinished(theoryAnswerSet!.mode)} context={context}></AnswerSetWidget>);
            else if (current.name == TabNames.Inspection)
                return (<EfInspection answerSet={inspectionAnswerSet!} vehicleDetails={inspectionVehicleDetails!} finish={() => answersFinished(inspectionAnswerSet!.mode)} context={context}></EfInspection>);
            else if (current.name == TabNames.PracticalTest)
                return (<EfPracticalTest key={current.name} answerSet={practicalAnswerSet!} finish={() => answersFinished(practicalAnswerSet!.mode)} context={context}></EfPracticalTest>);
            else if (current.name == TabNames.Results)
                return (<EfResults key={current.name} results={results!} respondent={respondent!} examFlow={examFlow!} context={context}></EfResults>);
            else
                return current.render();
        } else
            return (<></>);
    }

    useEffect(() => {
        if (tabs != null && tabs.length > 0 && api != null && getCurrentTab() == null)
            setCurrentTab(tabs[0]);
    });

    if (session != null) {
        return (
            <div className="examflow">
                <div className="examflow-tabs">
                    {tabs!.map((tab) => {
                        return (
                            <div key={tab.name} className={tab.classNames} style={{ width: (100 / tabs.length) + "%" }} onClick={() => setCurrentTab(tab)}>
                                {tab.name}
                            </div>
                        )
                    })}
                </div>
                <div className="examflow-body">
                    {getTab()}
                </div>
            </div>
        );
    } else {
        return (
            <></>
        )
    }
}

export default ExamFlowWidget;