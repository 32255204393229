import { IInspectionResults, QuestionMark } from "../api/Api";
import { stringToEnum } from "../api/Helpers";
import EfResultsPendingScore from "./EfResultsPendingScore";

const EfInspectionResults: React.FC<{
    results: IInspectionResults;
}> = ({
    results
}) => {

        const getScore = () => {
            var mark = stringToEnum<QuestionMark>(results.overallMark, QuestionMark);
            if (mark == QuestionMark.Pass) {
                return (
                    <div className="results-score results-score-green">
                        Pass
                    </div>
                )

            } else if (mark == QuestionMark.Fail) {
                return (
                    <div className="results-score results-score-red">
                        Fail
                    </div>
                )

            } else if (mark == QuestionMark.NotMarked) {
                return (
                    <EfResultsPendingScore text="You instructor will complete your inspection"></EfResultsPendingScore>
                )

            } else
                throw new Error("Cannot handle: " + mark);
        }

        return (
            <div className="results-container">
                <div className="results-header">
                    <h5>Inspection</h5>
                </div>
                <div className="results-body">
                    <div className="results-numbers">
                        <div className="results-number">
                            <label>Items</label>
                            {results.numQuestions}
                        </div>
                        <div className="results-number results-green">
                            <label>Passed</label>
                            {results.numPassed}
                        </div>
                        <div className="results-number results-red">
                            <label>Failed</label>
                            {results.numFailed}
                        </div>
                        <div className="results-number results-blue">
                            <label>N/A</label>
                            {results.numNotApplicable}
                        </div>
                    </div>
                    {getScore()}
                </div>
            </div>
        )
    }

export default EfInspectionResults;