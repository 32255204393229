import { useState } from "react";
import { showAlert } from "../api/Helpers";

const VcrButtons: React.FC<{
    count: number,
    title: string,
    onIndexChanged: (index: number) => void,
    canFinish: () => Promise<boolean>;
    finish: () => void;
}> = ({
    count,
    title,
    onIndexChanged,
    canFinish,
    finish
}) => {

    const [index, _setIndex] = useState(0);

    const setIndex = (index: number) => {
        if (index < 0)
            index = 0;
        else if (index >= count)
            index = count - 1;
        _setIndex(index);

        onIndexChanged(index);
    }

    const doFinish = async () => {
        if (await canFinish())
            finish();
        else
            showAlert("Please make sure you have answered every question before finishing.");
    }

    const getNextButton = () => { 
        if (index < count - 1) {
            return (
                <button className="btn btn-lg btn-primary" onClick={() => setIndex(index + 1)}>Next <span className="oi oi-arrow-right"></span></button>
            )
        } else {
            return (
                <button className="btn btn-lg btn-success" onClick={() => doFinish()}>Finish <span className="oi oi-check"></span></button>
                )
        }
    }

    return (
        <div className="row my-3 vcrbuttons">
            <div className="col-md-4 vcrbuttons-left">
                <button className="btn btn-lg btn-secondary" disabled={index == 0} onClick={() => setIndex(index - 1)}>Previous</button>
            </div>
            <div className="col-md-4 text-center vcrbuttons-mid">
                <div className="vcrbuttons-index">
                    {index + 1} of {count}
                </div>
                <div className="vcrbuttons-title">
                    {title}
                </div>
            </div>
            <div className="col-md-4 text-end vcrbuttons-right">
                {getNextButton()}
            </div>
        </div>
    )
}

export default VcrButtons;