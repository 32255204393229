
const EfCaption: React.FC<{
    text: string
}> = ({
    text
}) => {
    return (
        <div className="row">
            <div className="col-md-12 examflow-caption">
                <h5>{text}</h5>
                <hr />
            </div>
        </div>
        )
    }

export default EfCaption;