import { NOTFOUND } from 'dns';
import React, { Component } from 'react';

interface INotFoundPageProps {
}

class NotFoundPage extends Component<INotFoundPageProps> {
    constructor(props: INotFoundPageProps) {
        super(props);
    }

    render() {
        return (
            <p>404: Not Found</p>
        );
    }
}

export default NotFoundPage;