import { askYesNo } from "../../api/Helpers";
import SignoffLabel from "./SignoffLabel";

const SignoffWidget: React.FC<{
	isSignedOff: boolean,
	signOff: () => void;
}> = ({
	isSignedOff,
	signOff
}) => {

	const handleSignoff = () => {
		askYesNo(() => {
			signOff();
		});
	}

	if (!(isSignedOff)) {
		return (
			<div className="my-2">
				<button className="btn btn-success btn-sm" onClick={() => handleSignoff()}>I Confirm This Statement</button>
			</div>
		);
	} else {
		return (
			<SignoffLabel isSignedOff={isSignedOff}></SignoffLabel>
		)
	}
}

export default SignoffWidget;