import { useEffect, useState } from "react"
import Api, { IAnswerAcknowledgement, IAnswerKeyItem, IAnswerSet, IQuestion, IQuestionGroup, IQuestionSetVersion, IScalarResponse, IShortQuestionWithNotes, QuestionAnswerMode, QuestionMark, QuestionTypeNames, useApi, useSession } from "../api/Api"
import { showAlert, showBubble, stringToEnum } from "../api/Helpers"
import QuestionContext from "../api/QuestionContext"
import { UiHelper } from "../api/UiHelper"
import QuestionWidget from "./QuestionWidget"
import VcrButtons from "./VcrButtons"

const AnswerSetWidget: React.FC<{
    answerSet: IAnswerSet,
    answerKey?: IAnswerKeyItem[],
    notes?: IShortQuestionWithNotes[],
    loadNotes?: boolean,
    hideNotesButton?: boolean,
    finish: () => void,
    context: QuestionContext
}> = ({
    answerSet,
    answerKey,
    notes,
    loadNotes,
    hideNotesButton,
    finish, 
    context
}) => {

    const [groupIndex, setGroupIndex] = useState(0);
    const [api, setApi] = useState<Api>();
    const [answers, setAnswers] = useState<{ [token: string]: string }>();
    const [marks, setMarks] = useState<{ [token: string]: QuestionMark }>();
    const [acknowledgements, setAcknowledgements] = useState<{ [token: string]: IAnswerAcknowledgement }>();
    const [nudge, setNudge] = useState(0);

    useApi(async (theApi) => {
        setApi(theApi);

        if (loadNotes) {
            notes = await theApi.getQuestionNotesForAnswerSetAsync(answerSet);
            setNudge(nudge + 1);
            console.log("loaded...");
        }
    });

    let version: IQuestionSetVersion
    if(answerSet != null)
        version = answerSet.version;

    useEffect(() => {
        var theAnswers: { [id: string]: string } = {};
        var theMarks: { [id: string]: QuestionMark } = {};
        var theAcknowledgements: { [id: string]: IAnswerAcknowledgement } = {};

        for (var answer of answerSet.answers) {
            theAnswers[answer.questionToken] = answer.answer;
            theMarks[answer.questionToken] = stringToEnum<QuestionMark>(answer.mark, QuestionMark);
            theAcknowledgements[answer.questionToken] = answer.acknowledgement;
        }

        setAnswers(theAnswers as any);
        setMarks(theMarks as any);
        setAcknowledgements(theAcknowledgements as any);

    }, []);

    const getGroup = (): IQuestionGroup | null => {
        if (version != null)
            return version.groups[groupIndex];
        else
            return null;
    }

    const getGroupTitle = (): string => {
        var group = getGroup();
        if (group != null)
            return group.name;
        else
            return "?";
    }

    const handleGroupChange = (index: number) => {
        setGroupIndex(index);
    }

    const answerChanged = async (question: IQuestion, value: any, commit: boolean) => {
        setAnswers({
            ...answers,
            [question.token]: value
        })

        if (commit) {

            // some of the types are set using their own methods, so we have to call this to change the state
            // in memory, but then we don't call up again...
            var type = question.type;
            if(type == QuestionTypeNames.Text || type == QuestionTypeNames.MultipleChoice || type == QuestionTypeNames.InspectionState)
                await api!.setAnswerForCurrentWorkspaceAsync(answerSet, question, value);
        }
    }

    const answerMarked = async (question: IQuestion, mark: QuestionMark) => {
        setMarks({
            ...marks,
            [question.token]: mark
        })

        await api!.markAnswerAsync(answerSet, question, mark);
    }

    const hasAnswer = (question: IQuestion): boolean => {
        return Object.keys(answers!).includes(question.token);
    }

    const getAnswerKey = (question: IQuestion): string | null => {
        if (answerKey != null) {
            for (var item of answerKey) {
                if (item.token == question.token)
                    return item.answer;
            }
        }
        return null;
    }

    const getNotes = (question: IQuestion): string[] | null => {
        if (notes != null) {
            for (var item of notes) {
                if (item.token === question.token)
                    return item.notes;
            }
        }
        return null;
    }

    const getQuestions = () => {
        var mode = stringToEnum<QuestionAnswerMode>(answerSet!.mode, QuestionAnswerMode);

        var group = getGroup();
        if (group != null) {
            return (
                <div className="row" key={group.token}>
                    <div className="col-md-2">
                    </div>
                    <div className="col-md-8">
                        <div className="answerset-group">
                            {group.questions.map((question) => {
                                return (
                                    <div className="my-3" key={question.token}>
                                        <QuestionWidget mode={mode} answerSet={answerSet} question={question} answer={answers![question.token]}
                                            hasAnswer={hasAnswer(question)} answerKey={getAnswerKey(question)} notes={getNotes(question)}
                                            hideNotesButton={hideNotesButton} answerChanged={(value, commit) => answerChanged(question, value, commit)}
                                            answerMarked={(mark) => answerMarked(question, mark)} acknowledgement={acknowledgements![question.token]}
                                            mark={marks![question.token]} context={context}></QuestionWidget>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    <div className="col-md-2">
                    </div>
                </div>
            )
        } else
            return (<></>)
    }

    const canFinish = async (): Promise<boolean> => {
        var response = await api!.canFinishAnswerSetForCurrentWorkspaceAsync(answerSet);
        return response.value;
    }

    const doFinish = async () => {
        if (await canFinish()) {
            UiHelper.confirm("Are you sure you want to submit your answers?", async () => {
                var mode = stringToEnum<QuestionAnswerMode>(answerSet.mode, QuestionAnswerMode);
                if (mode == QuestionAnswerMode.ReadWrite || mode == QuestionAnswerMode.Mark)
                    await api!.finishAnswerSetForCurrentWorkspaceAsync(answerSet);

                finish();
            });
        }
    }

    if (answerSet != null && answers != null && marks != null) {
        return (
            <div>
                {getQuestions()}
                <VcrButtons count={answerSet.version.groups.length} title={getGroupTitle()} onIndexChanged={(index) => handleGroupChange(index)}
                    canFinish={() => canFinish()} finish={() => doFinish()}></VcrButtons>
            </div>
        )
    } else
        return (<></>)
}

export default AnswerSetWidget;