export function showAlert(buf: string) {
    alert(buf);
}

export function showBubble(buf: string) {
    alert(buf);
}

export function stringToEnum<T>(value: string, enumType: any): T {
    if (value in enumType) {
        return enumType[value] as T;
    }
    throw new Error(`Invalid value: ${value}`);
}

export function askYesNo(message: string | (() => void), callback?: () => void) {

    let theMessage = "";
    let theCallback: () => void;
    if (typeof message === "string") {
        theMessage = message as string;
        theCallback = callback as (() => void);
    } else {
        theMessage = "Are you sure?";
        theCallback = message as (() => void);
    }

    // eslint-disable-next-line no-restricted-globals
    if(confirm(theMessage))
        theCallback();
}