import { FaExclamationTriangle } from "react-icons/fa";
import { ICourseResults, IExamFlow, IRespondent, WebUserType } from "../api/Api";
import QuestionContext from "../api/QuestionContext";
import EfCaption from "./EfCaption";
import EfInspectionResults from "./EfInspectionResults";
import EfPracticalTestResults from "./EfPracticalTestResults";
import EfTheoryTestResults from "./EfTheoryTestResults";

const EfResults: React.FC<{
    results: ICourseResults;
    respondent: IRespondent;
    examFlow: IExamFlow,
    context: QuestionContext
}> = ({
    results,
    respondent,
    examFlow,
    context
}) => {

    const renderSignOffIssues = () => {
        if (!(respondent.hasRespondentSignedOff) || !(respondent.hasInstructorSignedOff)) {
            let message = "";
            if (!(respondent.hasRespondentSignedOff) && !(respondent.hasInstructorSignedOff))
                message = "Results cannot be confirmed because neither the operator not the instructor have signed off the training and testing process."
            else if (!(respondent.hasRespondentSignedOff))
                message = "Results cannot be confirmed because the operator has not signed off the training and testing process."
            else if (!(respondent.hasInstructorSignedOff))
                message = "Results cannot be confirmed because the instructor has not signed off the training and testing process."

            return (
                <div className="alert alert-danger text-center">
                    <FaExclamationTriangle></FaExclamationTriangle> <span>{message}</span>
                </div>
            )

        } else
            return (<></>)
    }

    const getCaptionText = (): string => {
        if (context.userType === WebUserType.Respondent)
            return "Summary of your results:";
        else if (context.userType === WebUserType.User)
            return "Summary of operator's results:";
        else
            throw new Error("Cannot handle " + context.userType);
    }

    const renderInstructorAssistance = () => {
        if (examFlow != null && context != null && context.userType === WebUserType.User) {

        } 

        return (<></>)
    }

    if (results != null) {
        return (
            <div>
                <EfCaption text={getCaptionText()}></EfCaption>

                {renderSignOffIssues()}

                <div className="row">
                    <div className="col-md-4 pe-5">
                        {renderInstructorAssistance()}
                        <EfTheoryTestResults results={results.theoryTestResults}></EfTheoryTestResults>
                    </div>
                    <div className="col-md-4 ps-5 pe-5">
                        <EfInspectionResults results={results.inspectionResults}></EfInspectionResults>
                    </div>
                    <div className="col-md-4 ps-5">
                        <EfPracticalTestResults results={results.practicalTestResults}></EfPracticalTestResults>
                    </div>
                </div>
            </div>
        );

    } else
        return (<></>)
    }

export default EfResults;