import React from 'react';
import { IPracticalTestResults } from '../api/Api';

const EfPracticalTestResults: React.FC<{
    results: IPracticalTestResults;
}> = ({
    results,
}) => {

    const renderMajorFailures = () => {
        if (results.majorFailureReasons.length > 0) {
            return (
                <ol className="list-unstyled">
                    {results.majorFailureReasons.map((failure) => {
                        return (
                            <li className="mb-2">
                                {failure.name}
                            </li>
                        )
                    })}
                </ol>
            )
        } else {
            return (
                <div>
                    <i className="fa fa-info-circle"></i> No major faults
                </div>
            )
        }
    }

    if (results != null) {
        return (
            <div className="results-container">
                <div className="results-header">
                    <h5>Practical Test</h5>
                </div>
                <div className="results-body">
                    <div className="results-numbers">
                        <div className="results-number">
                            <div>
                                <label>Penalty Points</label>
                                {results.score}
                            </div>

                            <div>
                                <label>Major Faults</label>
                                {renderMajorFailures()}
                            </div>
                        </div>
                        {results.passFail === "Pass" && (
                            <div className="results-number results-green">
                                <label>{results.passFail}</label>
                            </div>
                        )}
                        {results.passFail === "Fail" && (
                            <div className="results-number results-red">
                                <label>{results.passFail}</label>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        )

    } else 
        return (<></>)
}

export default EfPracticalTestResults;